<ng-container *ngIf="type==='content';else dialogModal">
    <div class="modal-wrapper">
      <a (click)="onClose()" class="close-btn">
        <mat-icon>close</mat-icon>
      </a>
      <div class="header" *ngIf="title">{{title|translate|titlecase}}</div>
      <div class="modal-content-box custom-content">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>
  <ng-template #dialogModal>
    <div class="modal-wrapper confirmation-dialog">
      <div class="header" *ngIf="title">{{title|translate}}</div>
      <div [ngClass]="{'dialog-wrapper': dialog}" class="modal-content-box">
        <p class="dialog-header" *ngIf="header">{{header}}</p>
        <p class="dialog-content">{{dialog|translate}}</p>
      </div>
      <div class="footer-box">
        <div class="actions"
             fxLayoutAlign="center"
             fxLayoutGap="10px">
          <lib-skf-ui-button [content]="cancelText"
                             [isRounded]="true"
                             theme="grey"
                             (btnClick)="onCancel()"></lib-skf-ui-button>
          <lib-skf-ui-button [content]="confirmText"
                             [disabled]="isNotValid"
                             [isRounded]="true"
                             (btnClick)="onConfirm()"></lib-skf-ui-button>
        </div>
      </div>
    </div>
  </ng-template>
  
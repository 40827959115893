import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import Auth, { CognitoUser } from "@aws-amplify/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit {
  profile: any = {};

  constructor(private _router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.checkIfSignedIn();
    }, 600);
  }

  async checkIfSignedIn() {
    // this.profile = await Auth.currentUserInfo();
    this.profile = localStorage.getItem("UserProfile");

    setTimeout(() => {
      // if (this.profile === null || typeof this.profile.attributes === 'undefined') {
      if (this.profile === null) {
        document.location.assign(environment.loginurl);
      }
      else {
        this._router.navigate(['/home']);
      }
    }, 250);
  }

}

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { AuthService } from "../../Shared/Layout/auth/auth.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ConsumeAPIService {
  endpoint: String = "";
  httpOptions: Object = {};
  httpOptions2: Object = {};
  contentType = 'application/json';


  // this.httpOptions = new HttpHeaders(
  //     {'Access-Control-Allow-Origin': '*',
  //      'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
  //      'Access-Control-Allow-Methods': 'OPTIONS,PUT,GET,POST',
  //      'Content-Type': this.contentType
  // });

  constructor(private http: HttpClient, private _authService: AuthService) {
    this.httpOptions = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, Accept,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
        'Access-Control-Allow-Methods': 'OPTIONS,PUT,GET',
        'Content-Type': this.contentType
      });

    this.httpOptions2 = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, Accept,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
        'Access-Control-Allow-Methods': 'OPTIONS,PUT,GET',
        'Content-Type': 'application/vnd.ms-excel'
      });

    this.setendpoint(null);
    // this._authService.getCognitoAuthToken().then(data =>
    //   this.httpOptions = {
    //     headers: new HttpHeaders({
    //       "Content-Type": "application/json",
    //       //"Authorization": "Bearer " + data + ""
    //       "Authorization": `Bearer ${data}`
    //     })
    //   }
    // );
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public setendpoint(endpoint: string): String {
    if (endpoint === null || endpoint === '') {
      endpoint = environment.APIEndpoint;
    }
    if (endpoint.substr(endpoint.length - 1) != "/") {
      endpoint = endpoint + "/";
    }
    this.endpoint = endpoint;
    return this.endpoint;
  }

  public sethttpOptions(httpOptions) {
    this.httpOptions = httpOptions;
  }

  get(type: string): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    if (type.indexOf('?') >= 0) {
      return this.http.get(this.endpoint + type + "&currentUserID=" + userID, this.httpOptions).pipe(map(this.extractData));
    }
    else {
      return this.http.get(this.endpoint + type + "?currentUserID=" + userID, this.httpOptions).pipe(map(this.extractData));
    }
  }

  getbyID(type: string, id: string): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .get(this.endpoint + type + "/" + id + "&currentUserID=" + userID, this.httpOptions)
      .pipe(map(this.extractData));
  }



  getbyFile(type: string, file: File): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .get(this.endpoint + type + "/" + file + "&currentUserID=" + userID, this.httpOptions)
      .pipe(map(this.extractData));
  }

  post(type: string, item: JSON): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .post<any>(this.endpoint + type + "&currentUserID=" + userID, JSON.stringify(item), this.httpOptions)
      .pipe(
        //  tap(item => console.log(`added item w/ id=${item.id}`)),
        catchError(this.handleError<any>("addItem"))
      );
  }

  postwithoutid(type: string, item: JSON): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .post<any>(this.endpoint + type + "&currentUserID=" + userID, JSON.stringify(item), this.httpOptions)
      .pipe(
        // tap(item => console.log(`added item`)),
        catchError(this.handleError<any>("addItem"))
      );
  }

  postFile(type: string, file: File): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .post<any>(this.endpoint + type + "&currentUserID=" + userID, file, this.httpOptions)
      .pipe(
        //  tap(item => console.log(`added item`)),
        catchError(this.handleError<any>("addItem"))
      );
  }

  put(type: string, id: string, item: JSON): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .put(
        this.endpoint + type + "/" + id + "&currentUserID=" + userID,
        JSON.stringify(item),
        this.httpOptions
      )
      .pipe(
        //   tap(_ => console.log(`updated item id=${id}`)),
        catchError(this.handleError<any>("updateItem"))
      );
  }


  putWithoutId(type: string, item: JSON): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .put(
        this.endpoint + type + "&currentUserID=" + userID,
        JSON.stringify(item),

        this.httpOptions
      )
      .pipe(
        //   tap(_ => console.log(`updated item id`)),
        catchError(this.handleError<any>("updateItem"))
      );
  }
  putWithoutIdNonJson(type: string, item: any): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .put(
        this.endpoint + type + "&currentUserID=" + userID,
        item,
        this.httpOptions2
      )
      .pipe(
        //  tap(_ => console.log(`updated item id`)),
        catchError(this.handleError<any>("updateItem"))
      );
  }

  delete(type: string, id: string, item: JSON): Observable<any> {
    let userID = localStorage.getItem("userGlobalID");
    return this.http
      .delete<any>(this.endpoint + type + "/" + id + "&currentUserID=" + userID, this.httpOptions)
      .pipe(
        //  tap(_ => console.log(`deleted item id=${id}`)),
        catchError(this.handleError<any>("deleteItem"))
      );
  }

  /**
     * Generic method to communicate with server
     *
     * Used to send data to backend server
     *
     * @param {string} endpoint End point of API
     * @param {object} payload  Payload data that need to send to server
    */
  public httpPost({ endpoint, payload }, baseUrl?: string) {
    endpoint = this.addUserID(endpoint);
    
    if (baseUrl) {
      return this.http.post<any>(
        `${baseUrl}${endpoint}`,
        payload,
        this.httpOptions
      );
    } else {
      return this.http.post<any>(
        `${environment.APIEndpoint}${endpoint}`,
        payload,
        this.httpOptions
      );
    }
  }

  /**
   * Generic method to communicate with server
   *
   * Used to send data to backend server
   *
   * @param {string} endpoint End point of API
   * @param {object} payload  Payload data that need to send to server
  */
  public httpPut({ endpoint, payload }, baseUrl?: string) {
    endpoint = this.addUserID(endpoint);

    if (baseUrl) {
      return this.http.put<any>(
        `${baseUrl}${endpoint}`,
        payload,
        this.httpOptions
      );
    } else {
      return this.http.put<any>(
        `${environment.APIEndpoint}${endpoint}`,
        payload,
        this.httpOptions
      );
    }
  }

  /**
   * Generic method to communicate with server
   *
   * Used to get data from backend server
   *
   * @param {string} endpoint End point of API
  */
  public httpGet({ endpoint }, baseUrl?: string) {
    endpoint = this.addUserID(endpoint);

    if (baseUrl) {
      return this.http.get<any>(
        `${baseUrl}${endpoint}`, this.httpOptions
      );
    } else {
      return this.http.get<any>(
        `${environment.APIEndpoint}${endpoint}`, this.httpOptions
      );
    }
  }

  /**
   * Generic method to communicate with server
   *
   * Used to delete data from backend server
   *
   * @param {string} endpoint End point of API
  */
  public httpDelete({ endpoint }, baseUrl?: string) {
    endpoint = this.addUserID(endpoint);

    if (baseUrl) {
      return this.http.delete<any>(
        `${baseUrl}${endpoint}`, this.httpOptions
      );
    } else {
      return this.http.delete<any>(
        `${environment.APIEndpoint}${endpoint}`, this.httpOptions
      );
    }
  }

  private addUserID(endpoint): string {
    let userID = localStorage.getItem("userGlobalID");
    if (endpoint.indexOf('?') >= 0) {
      return endpoint + "&currentUserID=" + userID;
    }
    else {
      return endpoint + "?currentUserID=" + userID;
    }
  }
}

<mat-nav-list position="end">
  <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
  </a>

  <div id="" *ngIf="loaderBoolean">
    <mat-nav-list>
      <app-menu-list-item
        *ngFor="let item of navItems"
        [item]="item"
      ></app-menu-list-item>
    </mat-nav-list>
  </div>

  <div [innerHTML]="htmlstring"></div>
  <a *ngIf="showIlluminate" mat-list-item (click)="illuminateClick()">
    <span class="nav-caption">Illuminate</span>
  </a>

</mat-nav-list>

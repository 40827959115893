import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Location } from "@angular/common";
import { SKFUICardComponent } from "@skf/ui";
import { debounceTime, filter, tap } from "rxjs/operators";

@Component({
  selector: "app-compass-card",
  templateUrl: "./compass-card.component.html",
  styleUrls: ["./compass-card.component.scss"],
})
export class CompassCardComponent
  extends SKFUICardComponent
  implements OnInit, AfterViewInit
{
  @Input() public showAdvanceFilter = false;
  @Input() public showFlagToggle = false;
  @Input() public showThemeToggle = false;
  @Input() public showCreateAction = false;
  @Input() public entityName: string;
  @Input() public isThemeToggleActive: boolean = false;
  @Input() public isFlagToggleActive: boolean = false;
  @Output() public addEntity = new EventEmitter();
  @Output() public toggleFlag = new EventEmitter();
  @Output() public toggleTheme = new EventEmitter();
  @Output() public openFilterModal = new EventEmitter();

  constructor(location: Location) {
    super(location);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.tableMode) {
      this.searchControl.valueChanges
        .pipe(
          tap((val) => {
            if (val === "") {
              this.searchEvent.emit(val);
            }
          }),
          debounceTime(500),
          filter(
            (val) =>
              val.trim() !== "" && val.trim().length >= 3 && val.length <= 3998
          )
        )
        .subscribe((val) => this.searchEvent.emit(val));
    }
  }

  public triggerAddEntity() {
    this.addEntity.emit();
  }

  public doFlagToggle() {
    this.toggleFlag.emit();
  }

  public doThemeToggle() {
    this.toggleTheme.emit();
  }

  public openModal() {
    this.openFilterModal.emit();
  }

  public emitClear() {
    this.searchControl.setValue("");
  }
}

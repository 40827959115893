<form [formGroup]="signupForm">
  <h2>Create a new account</h2>

  <p>Email and Phone number must be in correct format.</p>
  <p>Phone number format xxxxxxxxxx (Ex: 2152361234).</p>
  <p>User ID: SKF Windows User ID</p>
  <p>Password length must be greater than or equal to 8.</p>
  <mat-form-field>
    <input
      matInput
      placeholder="Email"
      type="email"
      formControlName="email"
      autocomplete="email"
      required
    />
    <mat-hint *ngIf="!emailInput.value">Enter your email address</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <span matPrefix>{{ countryCode }} &nbsp;</span>
    <input
      type="tel"
      matInput
      placeholder="Telephone"
      formControlName="phone"
      autocomplete="tel"
      required
    />
    <mat-icon matSuffix class="cursor-pointer" (click)="selectCountryCode()"
      >mode_edit</mat-icon
    >
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      placeholder="First Name"
      type="text"
      formControlName="fname"
      autocomplete="given-name"
      required
    />
    <mat-hint *ngIf="!fnameInput.value">Enter your first name</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      placeholder="Last Name"
      type="text"
      formControlName="lname"
      autocomplete="family-name"
      required
    />
    <mat-hint *ngIf="!lnameInput.value">Enter your last name</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      placeholder="User ID"
      type="text"
      formControlName="userid"
      autocomplete=""
      required
    />
    <mat-hint *ngIf="!useridInput.value">Enter your last name</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      placeholder="Password"
      [type]="hide ? 'password' : 'text'"
      formControlName="password"
      required
    />
    <mat-icon matSuffix class="cursor-pointer" (click)="hide = !hide">{{
      hide ? "visibility_off" : "visibility"
    }}</mat-icon>
    <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint>
  </mat-form-field>
  <button
    mat-raised-button
    color="primary"
    (click)="signUp()"
    [disabled]="shouldEnableSubmit()"
  >
    Sign up
  </button>
  <p class="center">
    Already have an account? <a routerLink="/auth/signin">Sign In</a>
  </p>
</form>

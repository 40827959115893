<div *ngIf="breadScrumVisible">
 <div class="skf-breadcrumbs" fxLayoutGap="5px">
  <span class="skf-breadcrumb-item">
    <a [routerLink]="['../'+curModule.moduleName]">{{curModule.label}}</a>
  </span>
  <ng-container *ngFor="let breadcrumb of breadcrumbs">
    <span class="skf-breadcrumb-item"><mat-icon>navigate_next</mat-icon></span>
    <span class="skf-breadcrumb-item">
      <a [ngClass]="breadcrumb.status === false ? 'not-active': ''"
         [routerLink]="breadcrumb.url">
        {{breadcrumb.label}}
      </a>
    </span>
  </ng-container>
 </div>
</div>
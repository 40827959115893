<div class="search-view-wrapper" [ngStyle]="{'max-width': customWrapperMaxWidth}">
  <!-- <h2>{{'Select from'}} {{name}}</h2> -->
  <div class="white-box box-shadow" (clickOutside)="resetItems()" tabindex="1">
    <!-- search field -->
    <div class="search-box">
      <div class="search-field">
        <div class="mat-form-field-flex">
          <mat-icon matPrefix>search</mat-icon>
          <input [formControl]="formControl" type="search" placeholder="{{name}}" class="search-input" [ngStyle]="{'font-size': customFontSize}"
            (mousedown)="doInputTouch($event)" #searchControl>
          <!-- <input (focusout)="resetItems()" [formControl]="formControl" type="search" placeholder="Search" class="search-input" #searchControl> -->
        </div>
      </div>
    </div>

    <!-- search results -->
    <div class="checkbox-wrapper" id="scrolling">
      <div class="check-box" *ngFor="let item of filteredItems | async">
        <app-skf-checkbox [label]="item[labelKey]" (changeEvent)="updateSelection(item,$event.checked)"
          [checked]="updateCheckedSelection(item)"></app-skf-checkbox>
      </div>
    </div>
  </div>

  <!-- selection count -->
  <div class="selected-group-box" [ngStyle]="{'font-size': customFontSize}">
    {{selectedItems?.length}} {{name}} {{'selected'}}
  </div>

  <!-- selection chips -->
  <div *ngIf="selectedItems?.length>0" class="selected-label white-box">
    <mat-chip-list>
      <mat-chip *ngFor="let item of selectedItems" [removable]="!readonly" (removed)="remove(item)" [ngStyle]="{'font-size': customFontSize}">
        {{item[labelKey]}}
        <mat-icon matChipRemove *ngIf="!readonly">clear</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
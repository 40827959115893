import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skf-label',
  templateUrl: './skf-label.component.html',
  styleUrls: ['./skf-label.component.scss']
})
export class SkfLabelComponent implements OnInit {
  @Input() name = '';
  @Input() label = '';
  @Input() blankMode = false;

  constructor() {
  }

  ngOnInit() {
  }

}

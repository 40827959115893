import {Component, OnInit} from '@angular/core';
import { DataService } from '../../Services/data.service';
// import {SharedService} from '../../../services/shared.service';

@Component({
  selector: 'app-window-title',
  templateUrl: './window-title.component.html',
  styleUrls: ['./window-title.component.scss']
})
export class WindowTitleComponent implements OnInit {
  title: string;

  // constructor(private sharedService: SharedService) {
  //   this.sharedService.windowTitleSubject.subscribe(val => this.title = val);
  // }

  constructor(private data: DataService){}

  ngOnInit() {
    this.data.currentTitle.subscribe(title => this.title = title);
    // this.title = "New Order";
  }

}

import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CognitoUser } from 'amazon-cognito-identity-js';
import { NavItem } from 'e2e/src/app/Projects/Shared/navigation/nav-item';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  appName = localStorage.getItem("routerappname");

  appLogos = {
    adam: "../../../assets/logo-blue-adam.png",
    applicationsecurity: "../../../assets/logo-blue-app-sec.png",
    fileuploadutility: "../../../assets/File-Upload-Utility-header-logo.png",
    ondemandquoting: "../../../assets/logo-blue-on-demand-quoting.png",
    compass: "../../../assets/logo-blue-compass.png",
    resourcereadiness: "../../../assets/logo-blue-resource-readiness.png",
    cogs: "../../../assets/cogs-file-upload-letters-white.png",
    customerfeedbacksurvey: "../../../assets/logo-blue-customer-feedback-survey.png",
    customersolution: "../../../assets/logo-blue-customer-solution.png",
    elevate: "../../../assets/logo-blue-elevate.png",
    rep: "../../../assets/logo-blue-rep.png",
    onsite: "../../../assets/logo-blue-onsite.png",
    skfusanorthamericafacilitiesteam: "../../../assets/logo-blue-snaft.png",
    lotusnote: "../../../assets/logo-blue-lotus-notes.png",
    forecasting: "../../../assets/logo-blue-forecasting.png",
    applicationsecurityexternalapps: "../../../assets/AppSecMarioDB.png",
    forecastingaftermarket: "../../../assets/logo-blue-forecastingAF.png",
    p2p: "../../../assets/logo-blue-p2p.png",
    applicationsuitezipcode: "../../../assets/zipcode-blue.png",
    customerrequestsandshippinginstructions: "../../../assets/logo-blue-customer-requests.png",
    importallocation: "../../../assets/ImportAllocationHeaderBlue.png"
  };

  siteEnv = environment.siteenv;
  siteEnvText = {
    "dev": "../../../assets/text-white-dev.png",
    "uat": "../../../assets/text-white-uat.png",
  }
  hideMenu: boolean = true;
  menuClass: string = "menu-hide";

  menu: any = [];
  constructor() { }

  menuItems;
  userDataString: string;
  userDataRows: any;
  rows: any;
  sidenavLoaded: string;
  profile: any = {};
  user: CognitoUser;
  APIobservable;
  APIresult;
  tool;
  resourceNames: string[];
  loaderBoolean = false;
  htmlstring;
  showIlluminate = false;
  //appname: string;

  navItems: NavItem[];

  ngOnInit() {
    if (this.appName != undefined && this.appName != null) {
      this.appName = this.appName.replace(/-/g, '');
      this.loadMenu();
    }
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  toggleHamburger() {
    if (this.menuClass == "menu-hide") {
      this.menuClass = "menu-show";
    } else {
      this.menuClass = "menu-hide";
    }
  }

  loadMenu() {
    let navitemsstr: string;
    // this.appname = localStorage.getItem("appname");
    let routerappname = localStorage.getItem("routerappname");
    if (routerappname === 'adam') {
      this.showIlluminate = true;
    }

    let userid = localStorage.getItem("userGlobalID");
    // let userid ='FH2121'

    if (userid === null) {
      return false;
    }

    this.userDataString = localStorage.getItem("userData");

    if (this.userDataString != "") {
      this.rows = JSON.parse(this.userDataString);
      this.resourceNames = this.rows.filter(record => {
        return (
          record.ResourceTypeKey === 3 &&
          record.PermissionKey === 4 &&
          record.ShowOnMenu === true
        );
      });


      let record;
      let routeval = "";
      let submenufound: boolean = false;
      let submenuName;

      // need to build menu based on user data
      navitemsstr = "[";
      for (let i = 0; i < this.resourceNames.length; ++i) {
        record = this.resourceNames[i];
        // console.log("record==", record);

        // calculate route value
        let updatedParam = record.ResourceName.replace(/ /g, "-");
        routeval = routerappname + "/" + updatedParam.toLowerCase();

        if (i > 0) {
          navitemsstr += ",";
        }

        if (record.SubMenuName === null || record.SubMenuName === "") {
          if (submenufound) {
            navitemsstr = navitemsstr.substr(0, navitemsstr.length - 1);
            navitemsstr += "]},";
          }
          submenufound = false;

          navitemsstr +=
            '{"label": "' + record.MenuText + '",' +
            ' "submenu": "no", ' +
            ' "main": [{' +
            ' "state": "' + routeval + '",' +
            ' "name": "' + record.MenuText + '",' +
            ' "type": "link",' +
            ' "icon": "ti-tag"' +
            // '}]}';
            ',' +
            '"children": [ {' +
            '"state": "compose",' +
            '"name": "Compose",' +
            '"type": "link",' +
            '"icon": "ti-crown"' +
            '}]' +
            '}]}';



        } else {
          // submenu logic
          if (!submenufound || submenuName != record.SubMenuName) {
            if (submenufound) {
              navitemsstr = navitemsstr.substr(0, navitemsstr.length - 1);
              navitemsstr += "]},";
            }

            navitemsstr +=
              '{ "label": "' + record.SubMenuName + '", ' +
              '  "submenu": "yes", ' +
              '   "main": [';
            submenuName = record.SubMenuName;
          }

          navitemsstr +=
            '{"state": "' +
            routeval +
            '", "name": "' +
            record.MenuText +
            '"}';

          submenufound = true;
        }
      }

      // if submenu item was the last item was the last one in menu then children loop is not closed, so close below
      if (submenufound) {
        navitemsstr += "]}";
      }
      navitemsstr += "]";

      // console.log(JSON.parse(navitemsstr));

      this.navItems = JSON.parse(navitemsstr);
    //  console.log("navItem =====" , this.navItems);

      // this.htmlstring = this.appname;
      // console.log("this.loaderBoolean  " + this.loaderBoolean, this.navItems);
      this.loaderBoolean = true;
      // this.navService.setItems(this.navItems);
      this.menuItems = this.navItems;
      // console.log("navItem menuitems=====" , this.menuItems[0].main[0].state);

      //this.ref.detectChanges();
      // });
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
// import {SharedService} from '../../../services/shared.service';
import {filter, startWith} from 'rxjs/operators';
// import {moduleLabels} from '../../../_config/moduleConfig';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  tempState = [];
  breadcrumbs: Array<Object>;
  breadScrumVisible = true;

  // curModule: { label: string, moduleName: string };
  curModule = {url: "url", moduleName: "lotus-note",status:true, label:"Main Page"};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    // public sharedService: SharedService) {
    ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(this.route.snapshot)
      )
      .subscribe(event => {  // note, we don't use event
        this.breadcrumbs = [];
        this.tempState = [];
        let currentRoute = this.route.root,
          url = '';
        do {
          const childrenRoutes = currentRoute.children;
          // console.log('childrenRoutes', childrenRoutes);
          currentRoute = null;
          childrenRoutes.forEach(routes => {
            // console.log('routes: ', routes);
            if (routes.outlet === 'primary') {
              const routeSnapshot = routes.snapshot;
              url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              if (routes.snapshot.data.breadcrumb !== undefined) {
                let status = true;
                this.breadScrumVisible = true;
                if (routes.snapshot.data.status !== undefined) {
                  status = routes.snapshot.data.status;
                }
                if (!this.tempState.includes(routes.snapshot.data.breadcrumb)) {
                  this.tempState.push(routes.snapshot.data.breadcrumb);
                  this.breadcrumbs.push({
                    label: routes.snapshot.data.breadcrumb,
                    status: status,
                    url: url
                  });
                }
              }else{
                this.breadScrumVisible = false;
              }
              currentRoute = routes;
            }
          });
        } while (currentRoute);
        // console.log('this.breadcrumbs', this.breadcrumbs);
      });
    // this.sharedService.curModuleSubject.subscribe(val => this.curModule = {label: moduleLabels.get(val), moduleName: val});
  }

  ngOnInit() {
  }

}

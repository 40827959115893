<div class="skf-text-area">
  <label [for]="name" class="skf-label">{{label|uppercase}}</label>
  <textarea [formControl]="control"
            [name]="name"
            [rows]="rows" [cols]="cols"
            class="textarea-content"
            [class.field-error]="control.touched && control.invalid"
            [placeholder]="placeholder"
            autocomplete="off"
            #textAreaInput></textarea>
</div>

import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import {trigger, transition, style, state, animate, animation } from '@angular/animations';

@Component({
  selector: 'app-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.scss']
 
})

export class DialogBodyComponent {
  message: string = "";
  cancelButtonText = "Cancel";
  type: string = "";
  backColor = " ";
  constructor(

    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogBodyComponent>) {
    if (data) {
       this.type = data.type;
      //  console.log("Data Type");
      //  console.log(this.type);
       if(this.type === "Warning"){
        this.backColor = "rgb(243, 243, 151)";
       }
       else if(this.type === "Notification"){
        this.backColor = "rgb(133, 155, 133)";
       }
       else if (this.type === "Error"){
        this.backColor = "rgb(243, 139, 139);";
       }

      this.message = data.message || this.message;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    this.dialogRef.updateSize('300vw','300vw')
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

 
}

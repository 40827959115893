import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularDraggableModule } from "angular2-draggable";
import { PersistenceModule } from "angular-persistence";
import { AppComponent } from "./app.component";
import { AlyleManagerModule } from "./Projects/Shared/Layout/alyle-manager/alyle-manager.module";
import { HomeComponent } from "./Projects/Shared/Layout/home/home.component";
import { NotFoundComponent } from "./Projects/Shared/Layout/not-found/not-found.component";
import { FooterComponent } from "./Projects/Shared/Layout/footer/footer.component";
import { HeaderComponent } from "./Projects/Shared/Layout/header/header.component";
import { AuthComponent } from "./Projects/Shared/Layout/auth/auth.component";
import { LoaderComponent } from "./Projects/Shared/Layout/loader/loader.component";
import { CountryCodeSelectComponent } from "./Projects/Shared/Layout/auth/country-code-select/country-code-select.component";
import { FilterPipe } from "./Projects/Shared/Layout/auth/country-code-select/filter.pipe";
import { SignInComponent } from "./Projects/Shared/Layout/auth/sign-in/sign-in.component";
import { SignUpComponent } from "./Projects/Shared/Layout/auth/sign-up/sign-up.component";
import { ConfirmCodeComponent } from "./Projects/Shared/Layout/auth/confirm-code/confirm-code.component";
import { ProfileComponent } from "./Projects/Shared/Layout/auth/profile/profile.component";
import { AvatarComponent } from "./Projects/Shared/Layout/auth/profile/avatar/avatar.component";
import { EllipsisPipe } from "./Projects/Shared/Pipes/ellipses.pipe";
import { LyThemeModule, LY_THEME, LY_THEME_GLOBAL_VARIABLES } from "@alyle/ui";
import { MinimaLight, MinimaDark } from "@alyle/ui/themes/minima";
import { LyButtonModule } from "@alyle/ui/button";
import { LyToolbarModule } from "@alyle/ui/toolbar";
import { LyResizingCroppingImageModule } from "@alyle/ui/resizing-cropping-images";
import { MatSliderModule } from "@angular/material/slider";
import { MatTableModule } from "@angular/material/table";
import { MatIcon } from "@angular/material/icon";
import {
  MatSidenavContainer,
  MatSidenavContent,
} from "@angular/material/sidenav";
import { MatSidenavModule } from "@angular/material/sidenav";
import { SidenavListComponent } from "./Projects/Shared/navigation/sidenav-list/sidenav-list.component";
import { LandingPageComponent } from "./Projects/Shared/Layout/landing-page/landing-page.component";
// import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { NgxSpinnerModule } from "ngx-spinner";
import { MenuListItemComponent } from "./Projects/Shared/navigation/menu-list-item/menu-list-item.component";
import { NavService } from "./Projects/Shared/navigation/nav.service";
import { DialogBodyComponent } from "src/app/Projects/Shared/dialog-body/dialog-body.component";
import { MatDialogModule } from "@angular/material/dialog";
import { TokenComponent } from "./Projects/Shared/Layout/auth/token/token.component";
import { SharedModule } from "src/app/Projects/Shared/shared.module";
import { LocalService } from "./Projects/Shared/Services/local.service";
import { StorageService } from "./Projects/Shared/Services/storage.service";
import { ConfirmationDialogComponent } from "src/app/Projects/Shared/confirmation-dialog/confirmation-dialog.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
// import {InterceptorService} from './Projects/shared/services/interceptor.service';
import { InterceptorService } from "./services/interceptor.service";
import { SKFAuthModule } from "@skf/auth";
import { authConfig } from "@app/_config/authConfig";
import { ToastrModule } from "ngx-toastr";
import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";
import { TranslateModule } from "@ngx-translate/core";
import { NgxUiLoaderConfig, NgxUiLoaderModule } from "ngx-ui-loader";
import { LoaderService } from "./services/loader.service";
import { NgxSpinnerLoaderService } from "./services/ngx-spinner.service";
import { MatSelectModule } from "@angular/material/select";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SKFUICommonService, SKFUILayoutModule } from "@skf/ui";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';


const ngxloaderConfig = {
  bgsColor: "#0f58d6",
  bgsOpacity: 0,
  bgsPosition: "bottom-right",
  bgsSize: 20,
  bgsType: "ball-spin-clockwise",
  blur: 0,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "#0f58d6",
  fgsPosition: "center-center",
  fgsSize: 50,
  fgsType: "three-strings",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 120,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(255,255,255,0)",
  pbColor: "red",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: false,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 300,
} as NgxUiLoaderConfig;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    FooterComponent,
    HeaderComponent,
    AuthComponent,
    LoaderComponent,
    CountryCodeSelectComponent,
    FilterPipe,
    SignInComponent,
    SignUpComponent,
    ConfirmCodeComponent,
    ProfileComponent,
    AvatarComponent,
    EllipsisPipe,
    SidenavListComponent,
    LandingPageComponent,
    MenuListItemComponent,
    DialogBodyComponent,
    ConfirmationDialogComponent,
    TokenComponent,
    TokenComponent
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AlyleManagerModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AngularDraggableModule,
    PersistenceModule,
    MatTableModule,
    MatSliderModule,
    MatSidenavModule,
    LyThemeModule.setTheme("minima-light"),
    LyResizingCroppingImageModule,
    LyToolbarModule,
    LyButtonModule,
    LyThemeModule,
    MatDialogModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    SKFAuthModule.forRoot(authConfig),
    ToastrModule.forRoot(),
    SatDatepickerModule,
    SatNativeDateModule,
    DragDropModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),
    SKFUILayoutModule,
    // ToastrModule
    // MatSelectModule,
    // MatToolbarModule,
    // // NgxMatSelectSearchModule,
    // NgxMatSelectSearchModule,
    // MatSelectModule,
    // MatFormFieldModule,
    // TranslateModule.forChild({extend: true}),
    NgxUiLoaderModule.forRoot(ngxloaderConfig),
    MatSelectModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    MatSidenavContainer,
    MatIcon,
    MatSidenavContent,
    SidenavListComponent,
    NavService,
    LocalService,
    StorageService,
    LoaderService,
    NgxSpinnerLoaderService,
    MatSelectModule,
    SKFUICommonService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}


<div id="myModal" class = "dialog-modal">
 <div class = "header text-center" [style.background-color] = "backColor" >
  <h2 mat-dialog-title>{{ type }}</h2>
</div>
<p></p><p></p>
<mat-dialog-content class="text-center">
    {{message}}
</mat-dialog-content>
<p></p><p></p><p></p>
<mat-dialog-actions class="item-center">
<button mat-raised-button color="primary" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>
</div>  
 
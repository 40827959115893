<section>
  <div class="skf-card-header" fxLayout="row" fxLayoutGap="10px">
    <span class="skf-card-title" *ngIf="title && tableMode">{{ title }}</span>
    <div class="header-wrapper" *ngIf="tableMode; else normalMode">
      <div
        *ngIf="showAdvanceFilter"
        class="action-wrapper"
        (click)="openModal()"
      >
        <img src="../../assets/icon/skf/advance-filter.svg" alt="" />
        <span class="p-l-10">Advanced Filter</span>
      </div>
      <div
        [ngClass]="{ active: isFlagToggleActive }"
        *ngIf="showFlagToggle"
        class="action-wrapper"
        (click)="doFlagToggle()"
      >
        <img src="../../assets/icon/skf/flag.svg" alt="" />
        <span class="p-l-10">Flagged</span>
      </div>
      <div
        [ngClass]="{ active: isThemeToggleActive }"
        *ngIf="showThemeToggle"
        class="action-wrapper themed-btn"
        (click)="doThemeToggle()"
      >
        <span class="p-l-10">Themed</span>
      </div>
      <div class="skf-card-search">
        <mat-form-field class="card-search" appearance="none">
          <mat-icon matPrefix>search</mat-icon>
          <input
            [formControl]="searchControl"
            name="search"
            matInput
            type="search"
            autocomplete="off"
            placeholder="Search "
            class="search-input"
          />
          <button
            mat-button
            *ngIf="searchControl.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="emitClear()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <ng-template #normalMode>
      <div class="card-content-wrapper">
        <span *ngIf="showBackArrow">
          <button mat-icon-button (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </span>
        <span class="skf-card-title" *ngIf="title">{{ title }}</span>
        <span class="menu-spacer"></span>
        <div class="right-side-action-items cursor-pointer">
          <div
            *ngIf="showCreateAction"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="5px"
            (click)="triggerAddEntity()"
          >
            <mat-icon>add</mat-icon>
            <span class="entity-name">Add {{ entityName }}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="skf-card-body" [class.table-mode]="tableMode">
    <ng-content></ng-content>
  </div>
</section>

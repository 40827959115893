import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRippleModule } from "@angular/material/core";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { SkfCardComponent } from "./components/skf-card/skf-card.component";
import { SkfDataTableComponent } from "./components/skf-data-table/skf-data-table.component";
import { SkfTextFieldComponent } from "./components/skf-text-field/skf-text-field.component";
import { SkfTextAreaComponent } from "./components/skf-text-area/skf-text-area.component";
import { SkfCheckboxComponent } from "./components/skf-checkbox/skf-checkbox.component";
import { SkfButtonComponent } from "./components/skf-button/skf-button.component";
import { SearchSelectFormComponent } from "./components/search-select-form/search-select-form.component";
import { SkfAutocompleteComponent } from "./components/skf-autocomplete/skf-autocomplete.component";
import { SkfLabelComponent } from "./components/skf-label/skf-label.component";
import { SkfToggleComponent } from "./components/skf-toggle/skf-toggle.component";
import { SkfModalComponent } from "./components/skf-modal-ui/skf-modal.component";
import { TranslateModule } from "@ngx-translate/core";
import { SkfSearchModalViewComponent } from "./components/skf-search-modal-view/skf-search-modal-view.component";
import { SkfDropdownModalComponent } from "./components/skf-dropdown-modal/skf-dropdown-modal.component";
import { ClickOutsideModule } from "ng-click-outside";
import { SkfInlineTableComponent } from "./components/skf-inline-table/skf-inline-table.component";

@NgModule({
  declarations: [
    SkfDataTableComponent,
    SkfCardComponent,
    SkfTextFieldComponent,
    SkfCheckboxComponent,
    SkfTextAreaComponent,
    SkfButtonComponent,
    SearchSelectFormComponent,
    SkfAutocompleteComponent,
    SkfLabelComponent,
    SkfToggleComponent,
    SkfModalComponent,
    SkfSearchModalViewComponent,
    SkfDropdownModalComponent,
    SkfInlineTableComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    // material modules
    MatTableModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatRippleModule,
    MatChipsModule,
    MatButtonModule,
    TranslateModule.forChild({ extend: true }),
    ClickOutsideModule,
    FormsModule,
  ],
  exports: [
    SkfDataTableComponent,
    SkfCardComponent,
    SkfTextFieldComponent,
    SkfTextAreaComponent,
    SkfCheckboxComponent,
    SkfButtonComponent,
    SkfAutocompleteComponent,
    SkfLabelComponent,
    SkfToggleComponent,
    SkfModalComponent,
    SkfSearchModalViewComponent,
    SkfDropdownModalComponent,
    SkfInlineTableComponent,
  ],
})
export class SkfUiModule {}

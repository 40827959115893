import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** Import Alyle UI */
import {
  LyThemeModule,
  LY_THEME
} from '@alyle/ui';

/** Import the component modules */
import { LyButtonModule } from '@alyle/ui/button';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { LyFieldModule } from '@alyle/ui/field';
import { LyGridModule } from '@alyle/ui/grid';
import { LySelectModule } from '@alyle/ui/select';
import { LyCardModule } from '@alyle/ui/card';
import { LyIconModule } from '@alyle/ui/icon';
import { LyTabsModule } from '@alyle/ui/tabs';

/** Import themes */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // Set main theme
    LyThemeModule.setTheme('minima-light'),
    // Add components
    LyButtonModule,
    LyToolbarModule,
    LyResizingCroppingImageModule,
    LyFieldModule,
    LyGridModule,
    LySelectModule,
    LyCardModule,
    LyIconModule,
    LyTabsModule
  ],
  exports: [
    // Set main theme
    LyThemeModule,
    // Add components
    LyButtonModule,
    LyToolbarModule,
    LyResizingCroppingImageModule,
    LyFieldModule,
    LyGridModule,
    LySelectModule,
    LyCardModule,
    LyIconModule,
    LyTabsModule
  ],
  /** Add themes */
  providers: [
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true } // name: `minima-dark`
  ]
})
export class AlyleManagerModule { }

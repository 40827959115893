<div *ngIf="showSidebar">
  <mat-drawer [class.expanded]="isExpanded" #drawer mode="side" disableClose="true" opened="true">
    <!-- expand/minimize sidenav toggle-->
    <span fxLayoutAlign="end">
      <a class="sidenav-toggle" (click)="isExpanded = !isExpanded">
        <img [src]="isExpanded?'../../assets/icon/skf/sidenav_minimize.svg':'../../assets/icon/skf/sidenav_expand.svg'"
          alt="">
      </a>
    </span>
    <!-- navigation items list -->
    <!-- [routerLink]="'/'+item.link" -->
    <mat-nav-list>
      <a mat-list-item *ngFor="let item of navItems" [routerLink]="'/'+item.link" routerLinkActive="active-item">
        <span class="nav-item-icon">
          <mat-icon mat-list-icon>
            <img [src]="'../../assets/icon/skf/'+item.icon+'.svg'" alt="" />
          </mat-icon>
        </span>
        <span *ngIf="isExpanded" [matBadge]="item.count" matBadgeOverlap="false" matBadgeColor='warn'
          [matBadgeHidden]="!item.count" class="nav-item-label">
          {{(item.name.length<15)?item.name:(item.name|slice:0:13)+'..'}} </span>
            <span *ngIf="isExpanded" class="nav-item-count"></span>
      </a>
    </mat-nav-list>
  </mat-drawer>
</div>

<!-- 
<div class="container">
    <h2>My Apps</h2>
    <div class="row">
        <div class="col-sm-8 " >
            <div *ngIf="appList">
                <div *ngFor="let item of appList" class="app-box">
                    <div class="icon-box">
                        <div (mouseover)="hoverStatus[item.name]=true" (mouseout)="hoverStatus[item.name]=false" routerLink='/adam/adam-status' class="icon"><img style="max-width:100%;" src="/assets/{{item.icon}}"></div> 
                    </div>
                    <div class="app-name-box">
                        {{item.name}}
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col-sm-4" >
            <div *ngIf="hoverStatus.ADAM == true" class="description-box">
                <div class="big-icon-box">
                        <img style="max-width:100%;" src="/assets/ADAM-logo-2.png">
                </div>
                <div class="description-text-box">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nisi erat, iaculis ac odio vitae, pellentesque euismod libero. 
                </div>
            </div>
            <div *ngIf="hoverStatus.RAC == true" class="description-box">
                <div class="big-icon-box">
                        <img style="max-height:100%;" src="/assets/skf-default-favicon.jpg">
                </div>
                <div class="description-text-box">
                        Proin odio justo, elementum eget auctor ut, placerat at arcu. Suspendisse id nisl sem. Mauris malesuada venenatis sagittis.
                </div>
            </div>
            <div *ngIf="hoverStatus.Illuminate == true" class="description-box">
                <div class="big-icon-box">
                        <img style="max-height:100%;" src="/assets/illuminate-favicon.png">
                </div>
                <div class="description-text-box">
                        Suspendisse potenti. Morbi sed orci pellentesque, luctus augue sed, fringilla dolor.
                </div>
            </div>
        </div>
    </div>
</div>   -->

<h3>Welcome to SKF My Apps</h3>
<h4>Please login to access the applications</h4>
<a style="font-size:larger;text-decoration: underline;" href="{{ loginurl }}">Login</a>
<div class="skf-autocomplete"
     [class.field-error]="formControl.touched && inError()">
  <label class="skf-label">{{label|uppercase}}</label>
  <div class="skf-autocomplete-wrapper">
    <mat-form-field floatLabel="never" appearance="none"
                    class="skf-autocomplete-field">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let item of selectedItems"
          [removable]="!readonly"
          (removed)="remove(item)">
          {{item[labelKey].length > 25 ? (item[labelKey]|slice:0:25) + '..' : item[labelKey]}}
          <mat-icon matChipRemove *ngIf="!readonly">clear</mat-icon>
        </mat-chip>
        <input
          placeholder="{{(!readonly
          ?((selectedItems?.length>0)?secondaryPlaceholder:placeholder)
          :'')}}"
          autocomplete="off"
          #autoCompleteInput
          [formControl]="formControl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputAddOnBlur]="addOnBlur"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="triggerEmit($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete"
                        (opened)="autoCompleteOpen(true)"
                        (closed)="autoCompleteOpen(false)"
                        (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of filteredItems | async"
                    [value]="item">
          {{item[labelKey]}}
        </mat-option>
      </mat-autocomplete>
      <button *ngIf="modalMode" class="btn-modal-view"
              mat-button matSuffix mat-icon-button
              (click)="emitModalMode()">
        <mat-icon>add_circle</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

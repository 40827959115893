import awsmobile from '../../aws-exports';
import {environment} from '@env/environment';
import {SKFAuthConfig} from '@skf/auth';

export const authConfig = {
  aws_cognito_user_pool_client_id: awsmobile.aws_user_pools_web_client_id,
  aws_cognito_oauth: awsmobile.oauth,
  aws_hosted_UI_URL: environment.loginurl,
  default_auth_route: 'auth'
} as SKFAuthConfig;

import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import { int } from 'aws-sdk/clients/datapipeline';

@Component({
  selector: 'app-skf-card',
  templateUrl: './skf-card.component.html',
  styleUrls: ['./skf-card.component.scss']
})
export class SkfCardComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() searchHelpText: string;
  @Input() searchTextLength: int =3;
  @Input() showBackArrow = false;
  @Input() tableMode = false;
  searchControl = new FormControl();
  @Output() searchEvent = new EventEmitter<string>();

  constructor(private location: Location) {
  }

  ngOnInit() {
    if (this.searchHelpText === "" || this.searchHelpText === null || this.searchHelpText === undefined) {
      this.searchHelpText = "search text";
    }

    if (this.tableMode) {
      this.searchControl.valueChanges.pipe(
        filter(val => val.trim() !== '' && val.trim().length >= this.searchTextLength),
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(val => this.searchEvent.emit(val));
    }
  }

  ngAfterViewInit() {
  }

  goBack() {
    this.location.back();
  }

  emitClear() {
    this.searchControl.setValue('');
    this.searchEvent.emit('');
  }

}

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from "@angular/core";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { ConsumeAPIService } from "../../Services/rest-api.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { NavItem } from "../nav-item";
import { NavService } from "../nav.service";
import { VERSION } from "@angular/material/core";
import { AuthService } from "../../Layout/auth/auth.service";
// import {MenuItems} from '../../menu-items';

@Component({
  selector: "app-sidenav-list",
  templateUrl: "./sidenav-list.component.html",
  styleUrls: ["./sidenav-list.component.scss"],
})
export class SidenavListComponent implements OnInit, OnChanges {
  @Output() sidenavClose = new EventEmitter();
  @Input() appname: string;
  // @Input() sidenavClose;
  // @ViewChild("appDrawer") appDrawer: ElementRef;
  version = VERSION;
  userDataString: string;
  userDataRows: any;
  rows: any;
  sidenavLoaded: string;
  profile: any = {};
  user: CognitoUser;
  APIobservable;
  APIresult;
  tool;
  resourceNames: string[];
  loaderBoolean = false;
  htmlstring;
  //appname: string;

  navItems: NavItem[];

  constructor(
    private API: ConsumeAPIService,
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    // public menuItems: MenuItems,
    private navService: NavService // readonly changeDetectorRef: ChangeDetectorRef, //private ref: ChangeDetectorRef
  ) {
    // API.setendpoint(environment.commonLambdasAPI);
  }

  showIlluminate = false;

  async ngOnInit() {
    let navitemsstr: string;
    this.appname = localStorage.getItem("appname");
    let routerappname = localStorage.getItem("routerappname");
    if (routerappname === "adam") {
      this.showIlluminate = true;
    }

    let userid = localStorage.getItem("userGlobalID");
    // let userid ='FH2121'

    if (userid === null) {
      return false;
    }

    this.userDataString = localStorage.getItem("userData");

    if (this.userDataString != "") {
      this.rows = JSON.parse(this.userDataString);
      this.resourceNames = this.rows.filter((record) => {
        return (
          record.ResourceTypeKey === 3 &&
          record.PermissionKey === 4 &&
          record.ShowOnMenu === true
        );
      });

      let record;
      let routeval = "";
      let submenufound: boolean = false;
      let submenuName;

      // need to build menu based on user data
      navitemsstr = "[";
      for (let i = 0; i < this.resourceNames.length; ++i) {
        record = this.resourceNames[i];
        //console.log(record);

        // calculate route value
        let updatedParam = record.ResourceName.replace(/ /g, "-");
        routeval = routerappname + "/" + updatedParam.toLowerCase();

        if (i > 0) {
          navitemsstr += ",";
        }

        if (record.SubMenuName === null || record.SubMenuName === "") {
          if (submenufound) {
            navitemsstr = navitemsstr.substr(0, navitemsstr.length - 1);
            navitemsstr += "]},";
          }
          submenufound = false;

          navitemsstr +=
            '{ "displayName": "' +
            record.MenuText +
            '", "route": "' +
            routeval +
            '"}';
        } else {
          // submenu logic
          if (!submenufound || submenuName != record.SubMenuName) {
            if (submenufound) {
              navitemsstr = navitemsstr.substr(0, navitemsstr.length - 1);
              navitemsstr += "]},";
            }

            navitemsstr +=
              '{ "displayName": "' + record.SubMenuName + '", "children": [';
            submenuName = record.SubMenuName;
          }

          navitemsstr +=
            '{ "displayName": "' +
            record.MenuText +
            '", "route": "' +
            routeval +
            '"}';

          submenufound = true;
        }
      }

      // if submenu item was the last item was the last one in menu then children loop is not closed, so close below
      if (submenufound) {
        navitemsstr += "]}";
      }
      navitemsstr += "]";

      // console.log(navitemsstr);

      this.navItems = JSON.parse(navitemsstr);
      // this.htmlstring = this.appname;
      // console.log("this.loaderBoolean  " + this.loaderBoolean, this.navItems);
      this.loaderBoolean = true;
      this.navService.setItems(this.navItems);
      //this.ref.detectChanges();
      // });
    }
  }

  public onSidenavClose = () => {
    //console.log("onSidenavClose in sidenav-list-comp");
    // console.log("xxxxx");

    this.sidenavClose.emit();
    this.navService.closeNav();
  };

  public illuminateClick = () => {
    window.open(environment.illuminateAppURL, "_blank");
  };

  signoutUser() {
    this._authService.signOut().then(() => this.router.navigate(["/"]));
  }

  /****
  goToNextPage(param: string) {
    let updatedParam = param.replace(/ /g, "-");
    let page1 = this.adamPages.find(o => o.name === updatedParam);
    if (page1) {
      updatedParam = page1.value;
    }
    let appname = localStorage.getItem("appname");
    this.router.navigate([appname + "/" + updatedParam.toLowerCase()], {
      relativeTo: this.route
    });
    this.onSidenavClose();
  }
  ***/
  ngOnChanges(changes) {
    //console.log("ngOnChanges this.appname", localStorage.getItem("appname"));
    // this.htmlstring = localStorage.getItem("appname");
    // this.changeDetectorRef.detectChanges();
  }
  ngAfterViewInit() {
    // this.changeDetectorRef.detectChanges();
  }
}

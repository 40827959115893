<ng-container *ngIf="type==='content';else dialogModal">
  <div class="modal-wrapper">
    <a (click)="onClose()" class="close-btn">
      <mat-icon>close</mat-icon>
    </a>
    <div class="header">{{title|titlecase}}</div>
    <div class="modal-content-box">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
<ng-template #dialogModal>
  <div class="modal-wrapper">
    <a (click)="onClose()" class="close-btn">
      <mat-icon>close</mat-icon>
    </a>
    <div class="modal-content-box">
      <mat-icon *ngIf="icon">{{icon}}</mat-icon>
      <p class="notification-content">{{title|titlecase}}</p>
    </div>
    <div class="footer-box">
      <p>{{dialog}}</p>
      <button class="m-r-15 default-button m-b-15"
              (click)="onCancel()">{{cancelText}}</button>
      <button class="primary m-r-15 m-b-15"
              (click)="onConfirm()">{{confirmText}}</button>
    </div>
  </div>
</ng-template>


<div class="select-from-wrapper">
    <h2>Select from groups</h2>
    <div class="white-box box-shadow">
        <div class="search-box">
            <div class="search-field">
                <div class="mat-form-field-flex">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput type="search" placeholder="search here" class="search-input">
                </div>
            </div>
        </div>
        <div class="checkbox-wrapper" id="scrolling">
            <div class="check-box">
                <mat-checkbox>Check me!</mat-checkbox>
            </div>
            <div class="check-box">
                <mat-checkbox>Check me!</mat-checkbox>
            </div>
            <div class="check-box">
                <mat-checkbox>Check me!</mat-checkbox>
            </div>
            <div class="check-box">
                <mat-checkbox>Check me!</mat-checkbox>
            </div>
            <div class="check-box">
                <mat-checkbox>Check me!</mat-checkbox>
            </div>
            <div class="check-box">
                <mat-checkbox>Check me!</mat-checkbox>
            </div>
            <div class="check-box">
                <mat-checkbox>Check me!</mat-checkbox>
            </div>
            <div class="check-box">
                <mat-checkbox>Check me!</mat-checkbox>
            </div>
        </div>
    </div>
    <div class="selected-group-box">
        6 group selected
    </div>
    <div class="selected-label white-box">
        <span class="selected-box selected-bg">checked 1 
            <span class="close-btn"><a href="#">
                <span class="material-icons">close</span></a>
        </span>
        </span>
        <span class="selected-box selected-bg">checked 1 
            <span class="close-btn"><a href="#">
                <span class="material-icons">close</span></a>
        </span>
        </span>
        <span class="selected-box selected-bg">checked 1 
            <span class="close-btn"><a href="#">
                <span class="material-icons">close</span></a>
        </span>
        </span>
        <span class="selected-box selected-bg">checked 1 
            <span class="close-btn"><a href="#">
                <span class="material-icons">close</span></a>
        </span>
        </span>
        <span class="selected-box selected-bg">checked 1 
            <span class="close-btn"><a href="#">
                <span class="material-icons">close</span></a>
        </span>
        </span>
        <span class="selected-box selected-bg">checked 1 
            <span class="close-btn"><a href="#">
                <span class="material-icons">close</span></a>
        </span>
        </span>

    </div>
    <div class="footer-button-wrapper">
        <button class="m-r-15 line-button">Cancel</button>
        <button class="primary">Confirm</button>
    </div>
</div>
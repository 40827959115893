import {
  Component,
  ViewChild,
  AfterViewInit,
  ElementRef,
  ViewContainerRef,
  ComponentFactoryResolver,
} from "@angular/core";
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import "rxjs/add/operator/filter";
import { LyTheme2, ThemeVariables } from "@alyle/ui";
import { Router, ActivatedRoute } from "@angular/router";
import { NavService } from "./Projects/Shared/navigation/nav.service";
import { MatSelectSearchVersion } from "ngx-mat-select-search";
import { VERSION } from "@angular/material/core";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/delay";
import { BehaviorSubject } from "rxjs";
import { DataService } from "./Projects/Shared/Services/data.service";

const STYLES = (theme: ThemeVariables) => ({
  "@global": {
    body: {
      backgroundColor: theme.background.default,
      color: theme.text.default,
      fontFamily: theme.typography.fontFamily,
      margin: 0,
      direction: theme.direction,
    },
  },
});

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0, 0, 0)",
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(100%, 0, 0)",
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out")),
    ]),
    trigger("slideOnOff", [
      state(
        "on",
        style({
          transform: "translate3d(0, 0, 0)",
        })
      ),
      state(
        "off",
        style({
          transform: "translate3d(100%, 0, 0)",
        })
      ),
      transition("on => off", animate("400ms ease-in-out")),
      transition("off => on", animate("400ms ease-in-out")),
    ]),
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px",
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE,
        })
      ),
      transition("no-block <=> yes-block", [animate("400ms ease-in-out")]),
    ]),
  ],
})
export class AppComponent implements AfterViewInit {
  @ViewChild("sidenav", { static: true }) sidenav: ElementRef;
  @ViewChild("sidenavcontainer", { read: ViewContainerRef })
  public spinnerType: string;
  entry: ViewContainerRef;
  readonly classes = this.theme.addStyleSheet(STYLES);
  headerFooter: boolean;
  homePage: boolean;
  appname: string;
  componentRef: any;
  version = VERSION;
  profile: any = {};
  config: any;
  titleValue: BehaviorSubject<any>;
  showNewTheme = true;
  isExpanded = false;
  isVisible = true;
  matSelectSearchVersion = MatSelectSearchVersion;
  showHeader = false;

  constructor(
    private theme: LyTheme2,
    public router: Router,
    private route: ActivatedRoute,
    private navService: NavService,
    private resolver: ComponentFactoryResolver,
    private data: DataService
  ) {}

  ngOnInit() {
    // setTimeout(() => {
    //   if (this.router.url != '/home' && this.router.url != '' && this.router.url != '/' && this.router.url != '/token' && this.router.url != '/auth/login') {
    //     this.showHeader = true;
    //   }
    // }, 50);

    this.data.currentTheme.subscribe(
      (newTheme) => (this.showNewTheme = newTheme)
    );

    if (this.showNewTheme === null) {
      this.showNewTheme = false;
    }
    // console.log('this.showNewTheme', this.showNewTheme);

    this.appname = localStorage.getItem("appname");

    setTimeout(() => {
      this.refreshsidenav();
    }, 100);
  }

  setTitleText(newValue) {
    this.titleValue.next(newValue);
  }

  ngAfterViewInit() {
    this.navService.sidenav = this.sidenav;
    this.spinnerType = "Ball Spin Clockwise Fade";
  }

  createComponent(param1: any) {}

  refreshsidenav() {
    this.createComponent(null);
  }

  destroyComponent() {
    this.componentRef.destroy();
  }
}

import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class NgxSpinnerLoaderService {
  constructor(private spinnerService: NgxSpinnerService) {}
  public show() {
    this.spinnerService.show();
    setTimeout(() => {
      /** spinner ends after 30 seconds */
      this.spinnerService.hide();
    }, 30000);
  }
  public hide() {
    this.spinnerService.hide();
  }
}

<ng-container *ngIf="showCardWrapper;else dataTable">
  <lib-skf-ui-card [tableMode]="true" (searchEvent)="doSearch($event)">
    <ng-container *ngTemplateOutlet="dataTable">
    </ng-container>
  </lib-skf-ui-card>
</ng-container>

<!-- table content -->
<ng-template #dataTable>
  <div *ngIf="tableInput && tableInput?.dataColumns?.length>0"
       class="table-wrapper" fxLayoutGap="10px" fxLayout="row">
    <!-- region delete multiple rows ui -->
    <div class="delete-wrapper">
      <a *ngIf="selectedRows?.length>0" class="btn-delete-items" fxLayoutGap="10px" (click)="multiDeleteItems()">
        <span class="delete-icon"><mat-icon>delete</mat-icon></span>
        <span> {{'Delete'|translate}} ({{selectedRows?.length}}
          ) {{(selectedRows?.length === 1 ? 'item' : 'items')|translate}}</span>
      </a>
    </div>
    <!-- endregion -->
    <!-- region select rows ui -->
    <div *ngIf="!disableSelection" class="select-wrapper" fxLayout="column">
            <span class="select-col-head">
      <lib-skf-ui-checkbox name="masterToggle"
                        (changeEvent)="changeMasterSelection($event)"
                        [checked]="isAllSelected()"
                        [indeterminate]="selectedRows?.length>0
                         && !isAllSelected()"></lib-skf-ui-checkbox>
    </span>
      <ng-container *ngFor="let col of tableInput?.dataSource?.getValue(); let i=index">
                <span class="select-col">
      <lib-skf-ui-checkbox (changeEvent)="changeRowSelection($event,i)"
                        [checked]="isRowSelected(i)"
      ></lib-skf-ui-checkbox>
    </span>
      </ng-container>
    </div>
    <!-- endregion -->
    <div class="table-content" fxLayout="column">
      <table matSort (matSortChange)="sortData($event)"
             mat-table [dataSource]="dataSource"
             class="mat-elevation-z8"
             cdkDropList
             [cdkDropListData]="dataSource"
             (cdkDropListDropped)="reorderTableRows($event)">
        <ng-container *ngIf="draggableTable" matColumnDef="dragHandle">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <span class="drag-td"><mat-icon class="drag-icon">drag_indicator</mat-icon></span>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of tableInput?.dataColumns;
         let i = index">
          <!-- ng material code for dynamic col-header and col-content -->
          <ng-container [matColumnDef]="column?.dataKey">
            <!-- region column header without sorting -->
            <ng-container *ngIf="!column?.isSortable;
                    else sortableColHeader">
              <th mat-header-cell *matHeaderCellDef>
                {{column?.displayLabel|translate|uppercase}}</th>
            </ng-container>
            <!-- endregion -->
            <!-- region sortable column header-->
            <ng-template #sortableColHeader>
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{column?.displayLabel|translate|uppercase}}</th>
            </ng-template>
            <!-- endregion -->
            <!-- region code for all columns except last-->
            <ng-container *ngIf="tableInput?.dataColumns?.length-1!==i;
        else lastCol">
              <td mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="colData;
            context:{element:element,column:column}"></ng-container>
              </td>
            </ng-container>
            <!-- endregion -->
            <!-- region code for action items appear on hover -->
            <ng-template #lastCol>
              <td mat-cell *matCellDef="let element" class="white-icon-box">
                                <span [class.hover-content]="tableInput?.actionItems
                   && tableInput?.actionItems?.length>0">
                    <ng-container *ngTemplateOutlet="colData;
            context:{element:element,column:column}"></ng-container>
                  </span>
                <div *ngIf="tableInput?.actionItems
                 && tableInput?.actionItems?.length>0" class="hover-icon">
                  <ng-container *ngFor="let action of tableInput?.actionItems">
                                        <span (click)="triggerTableAction(action?.action_name,
                         element[tableInput?.idKey])" class="material-icons">
                        {{action.mat_icon_name}}
                     </span>
                  </ng-container>
                </div>
              </td>
            </ng-template>
            <!-- endregion -->
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <ng-container *ngIf="!draggableTable;else draggableRow">
          <tr mat-row *matRowDef="let row; columns: displayColumns;"
              (dblclick)="triggerTableAction('rowClick',
                         row[tableInput?.idKey])"
              class="cursor-pointer"
              [ngClass]="{'row-read':row[tableInput
              .readKey]==1&&readUnreadMode,
        'row-not-read':row[tableInput?.readKey]!=1&&readUnreadMode}"></tr>
        </ng-container>
        <ng-template #draggableRow>
          <tr mat-row *matRowDef="let row; columns: displayColumns;"
              (dblclick)="triggerTableAction('rowClick',
                         row[tableInput?.idKey])"
              class="cursor-pointer"
              [ngClass]="{'row-read':row[tableInput
              .readKey]==1&&readUnreadMode,
        'row-not-read':row[tableInput?.readKey]!=1&&readUnreadMode}"
              cdkDrag [cdkDragData]="row"></tr>
        </ng-template>
      </table>
      <!-- no results -->
      <div class="no-results" *ngIf="tableInput?.dataSource?.getValue()?.length===0 && searchKey">
        <span>{{'No results found.'|translate}}</span>
      </div>
      <!-- region table paginator -->
      <div *ngIf="paginatorDetails?.totalCount>0" class="paginator-wrapper" fxLayoutAlign="end">
        <mat-paginator [length]="paginatorDetails?.totalCount" [pageSize]="paginatorDetails?.pageSize"
                       [hidePageSize]="true" [showFirstLastButtons]="true" (page)="triggerPageChange($event)">
        </mat-paginator>
      </div>
      <!-- endregion -->
    </div>
  </div>
</ng-template>

<!-- dynamic col-data for different types -->
<ng-template let-element='element' let-column='column' #colData>
  <ng-container [ngSwitch]="column?.type">
    <span *ngSwitchCase="'string'">
      {{element[column?.dataKey]}}
    </span>
    <span *ngSwitchCase="'date'">
      {{element[column?.dataKey]|date:dateFormat}}
    </span>
    <span *ngSwitchCase="'boolean'">
      {{(element[column?.dataKey] == 1 ? column?.validText : column?.invalidText)|translate}}
    </span>
    <span class="w-100" *ngSwitchCase="'status'">
      <button
        [ngClass]="{'btn-skf-active':element[column?.dataKey]==1,'btn-skf-inactive':element[column?.dataKey]==0}"
        mat-flat-button>
        {{(element[column?.dataKey] == 1
        ? (column?.validText ? column?.validText : 'active')
        : (column?.invalidText ? column?.invalidText : 'inactive'))|translate|uppercase}}
      </button>
    </span>
    <span class="link-icon" *ngSwitchCase="'link'">
      <a *ngIf="element[column?.dataKey]" [href]="element[column?.dataKey]" target="_blank">
        <mat-icon>{{column?.icon_name}}</mat-icon>
      </a>
    </span>
    <span *ngSwitchCase="'isactive_with_dot'">
        <span [ngClass]="{'green-dot': element[column?.dataKey] == true, 'inactive-state': element[column?.dataKey] == false}"></span>
      {{(element[column?.dataKey] == true ? column?.validText : column?.invalidText)|translate}}
    </span>
    <span class="string-icon" *ngSwitchCase="'status_with_icon'">
      <span><img
        [src]="iconPath(element[column?.dataKey])"
        width="16px"
        height="20px"
        alt=""/>
      </span>
      <span class="content">{{element[column?.dataKey]}}</span>
    </span>
    <span class="assignee" *ngSwitchCase="'assignee_with_icon'">
      <span [ngClass]="{'show-icon': canShowAssigneeIcon(element, column?.dataKey)}" class="assignee-icon"><img
        src="../../assets/icon/skf/assigned.svg"
        width="14px"
        alt="Assignee icon"/>
      </span>
      <span class="content">{{element[column?.dataKey]}}</span>
    </span>
    <span [ngClass]="{'show-flag': (element?.IsFlagged)}" class="score" *ngSwitchCase="'score'">
      <div [ngClass]="{'score-red': element[column?.dataKey] <= 6, 'score-orange': (element[column?.dataKey] > 6) && (element[column?.dataKey] <=8), 'score-green': element[column?.dataKey] >=9}" class="score-circle">{{element[column?.dataKey]}}</div>
    </span>
    <span *ngSwitchCase="'name_cum_account'">
      {{element[column?.dataKey]}}
      <div class="company-name">{{element?.Account}}</div>
    </span>
  </ng-container>
</ng-template>

import { Component, Inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { VERSION } from "@angular/material/core";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
  message: string = "";
  message2: string = "";
  confirmButtonText = "Yes";
  cancelButtonText = "No";
  type: string = "Confirmation";
  backColor = " ";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    if (data) {
      this.message = data.message || this.message;
      if (data.message2) {
        this.message2 = data.message2 || this.message2;
      }

      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }

      if(data.buttonText.type){
        this.type = data.buttonText.type || this.type;
      }
      if(this.type === "Warning"){
        this.backColor = "rgb(243, 243, 151)";
       }
       else if(this.type === "Confirmation"){
        this.backColor = "rgb(133, 155, 133)";
       }
       
    }
  }

  onConfirmClick(): void {
    if(this.confirmButtonText === 'Edit Records'){
      this.dialogRef.close("true");
    }else{
    this.dialogRef.close(true);
    }
  }

  onCancelClick(){
    if(this.cancelButtonText === 'Calculate Allocation'){
      this.dialogRef.close("false");
    }else{
    this.dialogRef.close(false);
    }
  }
}

import {
  Component,
  HostBinding,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { NavItem } from "../nav-item";
import { Router } from "@angular/router";
import { NavService } from "../nav.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
// import { EventEmitter } from "events";

@Component({
  selector: "app-menu-list-item",
  templateUrl: "./menu-list-item.component.html",
  styleUrls: ["./menu-list-item.component.scss"],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      ),
    ]),
  ],
})
export class MenuListItemComponent implements OnInit {
  // making expanded false by default to remove compilation error
  expanded: boolean = false;

  @Input() item: NavItem;
  @Input() depth: number;
  @Input() appname: string;
  @Output() sidenavClose = new EventEmitter();
  @HostBinding("attr.aria-expanded") ariaExpanded = this.expanded;

  constructor(public navService: NavService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  onItemSelected(item: NavItem) {
    //this.onSidenavClose();

    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  // public onSidenavClose = () => {
  //   console.log("onSidenavClose");
  //   this.sidenavClose.emit();
  // }
}

import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Directive()
@Injectable()
export class NavService {
  public sidenav: any;
  @Output() sidenavClose = new EventEmitter();
  public currentUrl = new BehaviorSubject<string>(undefined);
  itemList: any;

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public closeNav() {
    //console.log("closeNav");
    this.sidenav.close();
    //this.onSidenavClose();
  }

  // public onSidenavClose = () => {
  //   console.log("onSidenavClose");
  //   this.sidenavClose.emit();
  // };

  public openNav() {
    console.log("openNav");
    this.sidenav.open();
  }

  setItems(items: any) {
    this.itemList = items;
  }

  getItems() {
    console.log("ppppp");
    
    return this.itemList;
  }
}

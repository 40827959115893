import {Injectable} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor(private ngxLoader: NgxUiLoaderService) {
  }

  show() {
    this.ngxLoader.start();
  }

  hide() {
    this.ngxLoader.stop();
  }
}

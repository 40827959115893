import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-select-form',
  templateUrl: './search-select-form.component.html',
  styleUrls: ['./search-select-form.component.scss']
})
export class SearchSelectFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { SKFUIDataTableComponent } from '@skf/ui';

@Component({
  selector: 'app-custom-data-table',
  templateUrl: './custom-data-table.component.html',
  styleUrls: ['./custom-data-table.component.scss']
})
export class CustomDataTableComponent extends SKFUIDataTableComponent implements OnInit {
  @Input() public searchKey: string;

  constructor() {
    super();
  }

  ngOnInit() {
   this.setDisplayColumns();
   this.initDataSource();
  }

  public iconPath(name: string, iconName?: string) {
    if (iconName) {
      return `../../assets/icon/skf/${iconName}.svg`; 
    }

    let fileName = '';
    switch(name) {
      case 'Closed' :
        fileName = 'status-closed';
        break;
      case 'Open' :
        fileName = 'status-opened';
        break;
      case 'Escalated' :
        fileName = 'status-escalated';
        break;
      case 'Re-Opened' :
        fileName = 'status-reopened';
        break;
      case 'Overdue' :
        fileName = 'status-overdue';
        break;
      case 'In-Progress' :
        fileName = 'status-progress';
        break;
      default :
        fileName = name;
    }

    return `../../assets/icon/skf/${fileName}.svg`;
  }

  public canShowAssigneeIcon(record, columnName) {
    if (record && record.AssignedGADD) {
      if(columnName === 'Sales Rep.') {
        return record.AssignedGADD === record.SalesRepGADD;
      } else if (columnName === 'Sales Rep. Mgr.') {
        return (record.SalesRepGADD !== record.SalesRepMgrGADD && record.AssignedGADD === record.SalesRepMgrGADD);
      }
      return false;
    }
    return false;
  }

}

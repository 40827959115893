<!-- <div class="skf-text-field" [class.field-error]="control.touched
 && control.invalid"> -->
 <div class="skf-text-field"
     [class.sm]="isSm"
     [class.grey-theme]="isGrey"
     [class.field-error]="control.touched && control.invalid">
  <label [for]="name" class="skf-label">{{label|uppercase}}</label>
  <input [name]='name' [formControl]="control" [type]="type"
         [placeholder]="placeholder" autocomplete="off" #textInput />
</div>

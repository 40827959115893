<div class="container">
  <table>
    <tr>
      <td>
        <h2>My Apps</h2>
      </td>
     <td>
        <div *ngIf="devEnv" style="float: right;">
        <span>{{ this.currentUser }}</span>  &nbsp;
        <button          
        mat-raised-button  color="primary" (click)="selectImpersonateUser()">
         Impersonate User</button>
        </div>
      </td>  
      <td>
        <div *ngIf="isImpersonatID" style="float: right;">
         <input matInput placeholder="UserID" style="width: 100px;" [(ngModel)]="impersonateID">
         &nbsp;
         <button mat-raised-button color="primary" (click)="onChangeValue()" >
       Update</button>
       <button mat-raised-button style="margin-left: 10px;" color="primary" (click)="onClearValue()">
        Clear</button>
        </div>
      </td>
    </tr>
  </table>
    
  <hr />
  <div class="row">
    <div class="col-sm-9 ">
      <div *ngIf="appList">
        <div *ngFor="let item of appList" class="app-box">
          <div class="icon-box">
            <!-- <div (mouseover)="hoverStatus[item.name]=true" (mouseout)="hoverStatus[item.name]=false" routerLink='{{item.route}}' class="icon"><img style="max-width:100%;" src="/assets/{{item.icon}}"></div>  -->
            <div
              (mouseover)="item.hoverStatus = true"
              (mouseout)="item.hoverStatus = false"
              (click)="navigateToPath(item.route)"
              class="icon"
            >
              <img style="max-width:100%;" src="/assets/{{ item.icon }}" />
            </div>
          </div>
          <div class="app-name-box">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-2" >
      <div *ngFor="let item of appList" >
        <div *ngIf="item.hoverStatus == true" class="description-box">
          <div class="big-icon-box">
            <img style="max-height:100%;" src="/assets/{{ item.icon }}">
          </div>
          <div class="description-text-box">
            {{item.desc}}
          </div>
        </div>
      </div>  
  </div>
  </div>
</div>

import {Component, OnInit, Input} from '@angular/core';
// import {SKFNavItem} from '../../../_interfaces/SKFNavItem.interface';
// import {SharedService} from '../../../services/shared.service';
import { DataService } from '../../Services/data.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  //isExpanded = true;
  isExpanded = true;

  SKFNavItem: {
    icon?: string;
    name: string;
    link: string;
    count?: number;
  };
  // navItems: Array<SKFNavItem>;
  navItems=[];
  showSidebar=true;

  constructor(private data: DataService) {}

  ngOnInit(): void {
    //console.log('sidebar comp', this.navItems);
   //Show and hide SIdebar menu depends on SideBar menu list
    this.data.currentSideBarStatus.subscribe(isExpanded => this.isExpanded = isExpanded);

    //get value of sidebar menu
    this.data.currentsidebarValues.subscribe(navItems => {
      this.navItems = JSON.parse(navItems);
      //console.log('sidebar comp - this.navItems', this.navItems);
    });

    //get value of sidebar menu
    this.data.currentSidebarValue.subscribe(showSidebar => this.showSidebar = showSidebar);


  }

}

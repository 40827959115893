<!-- <ng4-loading-spinner [timeout]="30000"> </ng4-loading-spinner> -->
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  type="ball-spin-clockwise"
  size="medium"
></ngx-spinner>
<div [ngStyle]="{ 'overflow-x: scroll': !router?.url?.includes('compass') }">
  <app-header
    *ngIf="
      router.url != '/home' &&
      router.url != '' &&
      router.url != '/' &&
      router.url != '/token' &&
      router.url != '/auth/login' &&
      !router?.url?.includes('compass')
    "
  >
  </app-header>
  <app-custom-header
    *ngIf="router?.url?.includes('compass')"
  ></app-custom-header>

  <perfect-scrollbar
    [style.max-width]="'100%'"
    [style.max-height]="'100vh'"
    [config]="config"
  >
    <div
      [ngClass]="{ 'compass-root-container': router?.url?.includes('compass') }"
      class="skf-root-container"
      fxLayout="column"
    >
      <div
        [ngClass]="{
          'compass-main-container': router?.url?.includes('compass')
        }"
        class="skf-main-container"
        fxLayout="row"
      >
        <app-sidebar
          *ngIf="
            router.url != '/home' &&
            router.url != '' &&
            router.url != '/' &&
            router.url != '/token' &&
            !router?.url?.includes('compass') &&
            !router?.url?.includes('auth')
          "
          [isExpanded]="isExpanded"
        >
        </app-sidebar>

        <div
          [ngClass]="{
            'compass-content-container': router?.url?.includes('compass')
          }"
          class="skf-content-container"
          fxLayout="column"
        >
          <div class="details-bar" fxLayout="row">
            <app-window-title
              *ngIf="
                router.url != '/home' &&
                router.url != '' &&
                router.url != '/' &&
                router.url != '/token' &&
                router.url != '/auth/login'
              "
            >
            </app-window-title>
            <app-breadcrumbs
              fxLayoutAlign="end"
              *ngIf="
                router.url != '/home' &&
                router.url != '' &&
                router.url != '/' &&
                router.url != '/token' &&
                router.url != '/auth/login'
              "
              [breadScrumVisible]="isVisible"
            >
            </app-breadcrumbs>
          </div>

          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>

<app-footer
  *ngIf="
    router.url != '/home' &&
    router.url != '' &&
    router.url != '/' &&
    router.url != '/token' &&
    router.url != '/auth/login' &&
    !router?.url?.includes('compass')
  "
>
</app-footer>

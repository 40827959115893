import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-skf-button',
  templateUrl: './skf-button.component.html',
  styleUrls: ['./skf-button.component.scss']
})
export class SkfButtonComponent implements OnInit {
  @Input() content: string;
  @Input() theme = 'primary'; // 'secondary', 'link','grey' or 'line'
  @Input() disabled = false;
  @Input() isRounded = true;
  @Input() elevated = false; // show button as elevated
  @Output() btnClick = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  public doClick() {
    this.btnClick.emit();
  }

}

<div id="myModal" class="dialog-modal">
	<!-- <div class="header" style="background-color: #035FA0;">
		<h2 mat-dialog-title style="margin-left: 180px; color: white;">{{ type }}</h2>
	</div> -->
	<div class = "header text-center" [style.background-color] = "backColor" >
		<h2 mat-dialog-title>{{ type }}</h2>
		</div>
	<p></p>
	<p></p>
	<mat-dialog-content>
		<p style="text-align: center;">
			{{message}}
    </p>

		<p style="text-align: center;color: red">
			{{message2}}
		</p>
	</mat-dialog-content>
	<div style="align-items: center; justify-content: center;display: flex;flex-direction: row;">
		<mat-dialog-actions>
			<button mat-raised-button color="primary" (click)="onConfirmClick()"
				tabindex="1">{{confirmButtonText}}</button>
			<button mat-raised-button color="primary" mat-dialog-close tabindex="-1" 
			(click)="onCancelClick()">{{cancelButtonText}}</button>
		</mat-dialog-actions>
	</div>

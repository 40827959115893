import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-compass-modal',
  templateUrl: './compass-modal.component.html',
  styleUrls: ['./compass-modal.component.scss']
})
export class CompassModalComponent implements OnInit {
  @Input() public type = 'content'; // or dialog
  @Input() public title?: string;
  @Input() public header?: string;
  @Input() public dialog?: string;
  @Input() public icon?: string;
  @Input() public cancelText = 'Cancel';
  @Input() public confirmText = 'Yes';
  @Input() public isNotValid = false;
  @Output() public cancelEvent = new EventEmitter();
  @Output() public confirmEvent = new EventEmitter();
  @Output() public closeEvent = new EventEmitter();

  constructor() {
   
  }

  ngOnInit() {
  }

  public onClose() {
    this.closeEvent.emit();
  }

  public onCancel() {
    this.cancelEvent.emit();
  }

  public onConfirm() {
    this.confirmEvent.emit();
  }

}
